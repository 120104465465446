define([], function() {

  const getRenderedHeight = el => el.offsetHeight;

  const getCssHeight = (el) => { if(el) { return parseInt(el.style['height']); }};
  const setCssHeight = (el, val) => { if(el) { el.style['height'] = val+'px'; }};
  const resetCssHeight = (el) => { if(el) { el.style['height'] = ''; }};

  const hide = (el) => { if(el) { el.classList.add('hide'); }};
  const show = (el) => { if(el) { el.classList.remove('hide'); }};

  const productRecommendationSidebarVertical = () => {
    const component = {};

    component.computed = {
      get collapsedHeight() {
        return getRenderedHeight(component.children.footer) + component.props.contentMaxHeight;
      },

      get expandedHeight() {
        return getRenderedHeight(component.children.content) + getRenderedHeight(component.children.footer);
      },

      get isTrimNeeded() {
        return getRenderedHeight(component.children.content) > component.props.contentMaxHeight + getRenderedHeight(component.children.footer);
      },

      get isCollapsed() {
        return getCssHeight(component.element) === component.computed.collapsedHeight;
      },
    };

    const _config = {
      props: {
        contentMaxHeight: {
          selector: 'data-max-height',
          defaultValue: 300,
          transform: (n) => parseInt(n),
        },
        loadMoreText: {
          selector: 'data-load-more-text',
          defaultValue: 'Show more',
        },
        loadLessText: {
          selector: 'data-load-less-text',
          defaultValue: 'Show less',
        },
        showText: {
          selector: 'data-load-context-text',
          defaultValue: 'Show'
        },
        moreText: {
          selector: 'data-more-context-text',
          defaultValue: 'more'
        }
      },
      children: {
        content: '.productRecsRightCol',
        footer: '.loadmore_footer',
        footerButton: '.loadmore_footerButton',
      }
    }
    const _init = element => {
      component.element = element;

      component.setChildrenValue();
      component.setPropsValue();
      component.trimCheck();

      setTimeout(component.count, 1000);

      return component;
    };

    const _setChildrenValue = () => {
      component.children = {};
      for (let [childrenName, childrenSelector] of Object.entries(component.config.children)) {
          const queriedValue = component.element && component.element.querySelector(childrenSelector);
          if (queriedValue) {
            component.children[childrenName] = queriedValue;
          }
      }
    }

    const _setPropsValue = () => {
      component.props = {};
      for (let [propName, {selector, defaultValue, transform = x => x}] of Object.entries(component.config.props)) {
        const attributeValue = component.element && transform(component.element.getAttribute(selector) || defaultValue);
        if (attributeValue) {
          component.props[propName] = attributeValue;
        }
      }
    }

    const _trimCheck = () => {
      if (component.computed.isTrimNeeded) {
        show(component.children.footer);
        component.attachListeners();
        component.collapse();
      } else {
        hide(component.children.footer);
        resetCssHeight(component.element);
      }
    }

    const _attachListeners = () => {
      component.children.footerButton.addEventListener('click', component.toggle);
    };

    const _toggle = () => component.computed.isCollapsed ? component.expand() : component.collapse();

    const _count = () => {
      const selector = component.element.querySelectorAll(".productRecsRightCol_card");
      const selectorLength = selector && selector.length;
      let order;
      let totalHeight = getRenderedHeight(component.children.footer);

      if (selectorLength !== 0) {
        for(let i=0; i <= selectorLength-1; i++) {
          totalHeight += getRenderedHeight(selector[i]);

          if (totalHeight >= component.props.contentMaxHeight) {
            order = i+1;
            let item = (selectorLength - order);
            if (item !== 0) {
              component.children.footerButton.textContent = component.props.showText + " " +item+ " " + component.props.moreText;
            }
            return;
          }
        }
      }
    }

    const _collapse = () => {
      component.children.footerButton.textContent = component.props.loadMoreText;
      component.children.footerButton.setAttribute('aria-expanded', 'false');
      setCssHeight(component.element, component.computed.collapsedHeight);
      const item = component.count();
    };

    const _expand = () => {
      component.children.footerButton.textContent = component.props.loadLessText;
      component.children.footerButton.setAttribute('aria-expanded', 'true');
      setCssHeight(component.element, component.computed.expandedHeight);
    };

    component.config = _config;
    component.init = _init;
    component.toggle = _toggle;
    component.attachListeners = _attachListeners;
    component.collapse = _collapse;
    component.expand = _expand;
    component.setChildrenValue = _setChildrenValue;
    component.setPropsValue = _setPropsValue;
    component.trimCheck = _trimCheck;
    component.count = _count;

    return component;
  }

  return productRecommendationSidebarVertical;
});
